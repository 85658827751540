import React, { useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import BookingsRow from "./__row";
import InputNumber from "../input/__number";
import InputText from "../input/__text/input__text";
import InputEmail from "../input/__email/input__email";
import InputPhone from "../input/__phone";
import ApiBooking from "../../api/ApiBooking";
import SlotTimesDate from "../slot-times-date/slot-times-date";
import { setIsLoadUpdate } from "../../reducers/BookingPanel/BookingPanelActions";
import { loadConnectionsForBookings } from "../../reducerApi";
import DateLib from "../../inc/DateLib";

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import style from "./style";
import "./styles.css";

const apiBooking = new ApiBooking({});

function Bookings({ date }) {
    const dispatch = useDispatch();
    const isLoadUpdate = useSelector((state) => state.bookingPanel.isLoadUpdate);
    const [bookings, setBookings] = useState([]);
    const [id, setId] = useState('');
    const [fio, setFio] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [orderBySort, setOrderBySort] = useState('desc')

    const actionEditId = (e) => { setId(e.target.value) }
    const actionEditFio = (e) => { setFio(e.target.value) }
    const actionEditEmail = (e) => { setEmail(e.target.value) }
    const actionEditPhone = (e) => { setPhone(e.target.value) }

    const handleOrderChange = () => {
        switch(orderBySort){
            case 'desc':
                setOrderBySort('asc');
                break;
            case 'asc':
                setOrderBySort('desc');
                break;
        }
        console.log(orderBySort)
    }

    const loadBookings = async ({ id, email, phone, fio, dispatch }) => {
        let params = {
            pagination:100,
            orderBy: 'id',
            orderBySort: orderBySort
        };
        const data = await apiBooking.getBookings({ id, email, phone, fio, ...params });
        if (data) {
            loadConnectionsForBookings({ dispatch, bookings: data });
            setBookings(data);
        }
    }
    useLayoutEffect(() => {
        if (
            id.length > 1 ||
            email.length > 3 ||
            phone.length > 2 ||
            fio.length > 3
        ) loadBookings({ id, email, phone, fio, dispatch });
    }, [id, email, phone, fio, orderBySort, dispatch]);
    useLayoutEffect(() => {
        const load = async () => {
            dispatch(setIsLoadUpdate(false));
            loadBookings({ id, email, phone, fio, dispatch });
        }
        if (isLoadUpdate) load();
    }, [dispatch, isLoadUpdate, id, email, phone, fio, orderBySort]);
    let slotTimeDate = null;
    return <>
        <div className="bookings__search">
            <div onClick={handleOrderChange} className="bookings__search_item--icon">
                { orderBySort == 'desc' 
                    ? <ArrowDownwardIcon/>
                    : <ArrowUpwardIcon/>
                }
            </div>
            <div className="bookings__search_item--field">
                <InputNumber
                    label="Номер"
                    variant="standard"
                    value={id}
                    onChange={actionEditId}
                    sx={style.input}
                />
            </div>
            <div className="bookings__search_item--field">
                <InputText
                    label="Имя"
                    variant="standard"
                    value={fio}
                    onChange={actionEditFio}
                    sx={style.input}
                />
            </div>
            <div className="bookings__search_item--field">
                <InputEmail
                    label="E-mail"
                    variant="standard"
                    value={email}
                    onChange={actionEditEmail}
                    sx={style.input}
                />
            </div>
            <div className="bookings__search_item--field">
                <InputPhone
                    label="Телефон"
                    variant="standard"
                    value={phone}
                    onChange={actionEditPhone}
                    sx={style.input}
                />
            </div>
        </div>
        {bookings.map((booking, i) => {
            let slotTimesDateTitle = null;
            if (!slotTimeDate || slotTimeDate.getTime() !== booking.slotTimeDate.getTime()) {
                slotTimeDate = booking.slotTimeDate;
                slotTimesDateTitle = <div className="bookings__date">
                    <SlotTimesDate date={slotTimeDate} />
                </div>
            }
            return <>
                {slotTimesDateTitle}
                <div key={i} className={`grid-booking-content__bookings-row-wrapper bookings__item`}>
                    <div className="sticky-booking-content__bookings-row-col-1 bookings__item-datetime-wrapper">
                        <div className="bookings__item-datetime">
                            <div className="bookings__item-time">
                                {DateLib.formatterTime(booking.slotTimeDateTimeStart)}
                            </div>
                            <div className="bookings__item-date">
                                {DateLib.formatterDate(booking.slotTimeDateTimeStart)}
                            </div>
                        </div>
                    </div>
                    <BookingsRow
                        isSlotTimeDateTime={true}
                        booking={booking}
                    />
                </div>
            </>
        })}
    </>
}

export default Bookings;